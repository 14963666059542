import React, {Component} from 'react';
import Home from './home';

export default class Main extends Component {
    render() {
        return (
            <div class="wrapper">
                <div class="container">
                    <Home />
                </div>
            </div>
        );
    }

}