import React, { Component } from 'react';

export default class Home extends Component {
    render() {
        return (
            <div class="blurb">
                <h1>Hello! I'm Nithin Reddy</h1>
                <p>Human Being. Just like you. A little different. But same. We are one.</p>
                <p>And so, <a href="https://www.exploremeditation.com/namaste/">Namaste!</a></p>
                <p>I enjoy meeting new people and hearing their stories...something I shied away from when I was younger. So please get in touch and introduce yourself! I'm sure there's tons we can learn from eachother.</p>
                <p>You can email me directly at <b>nithinisreddy[at]gmail.com</b></p>
            </div>
        );
    }
}