import React, {Component} from 'react';

export default class Footer extends Component {
    render() {
        return (
          <footer>
                  <ul>
                      <li><a href="https://www.twitter.com/nithinisreddy">Find me on Twitter!</a></li>
                      <li><a href="https://www.instagram.com/nithinisreddy">Find me on Instagram!</a></li>
                  </ul>
          </footer>
        );
    }
}